@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

:root {
  --primary-color: #50DA4C;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  background: #1C1C1C;
  color: white;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  box-sizing: border-box;
}

/* .scroll-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}


.section {
  height: 100vh;
  scroll-snap-align: start;
} */