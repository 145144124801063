.countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countdown-timer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1b1b1b;
  border-radius: 48px;
  /* box-shadow: 0 20px 50px rgba(0, 255, 0, 0.2);  */
  box-shadow: 0px 10px 4px 0px #3C9D2140;
  width: 100%;
  padding: 20px;

}

.time-section {
  text-align: center;
  margin: 0px 20px;
  position: relative;
  padding: 16px 0px;
  width: 80px;
}

.time-section:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -20px;
  height: 100%;
  width: 1px;
  /* background-color: #00ff00;  */
  background-color: var(--primary-color);
}

.time-value {
  font-size: 3rem;
  /* color: #50DA4C; */
  color: var(--primary-color);
  font-weight: bold;
  padding: 20px;
  width: 100%;
}

.time-label {
  font-size: 1.2rem;
  color: #ffffff;
  margin-top: 10px;
  display: block;
}

.countdown-message {
  margin-top: 30px;
  font-size: 1.8rem;
  color: #ffffff;
}


/* For medium-sized screens (tablets, small laptops) */
@media (max-width: 1100px) {
  .countdown-timer {
    width: 100%;
    padding: 20px;
  }

  .time-section {
    width: 80px;
    /* Reduce width for medium-sized screens */
    margin: 0 10px;
    /* Adjust the spacing */

  }

  .time-value {
    font-size: 2.5rem;
    /* Adjust font size */
    padding: 16px;
    /* Reduce padding */
  }

  .time-label {
    font-size: 1rem;
    /* Adjust label font size */
  }
}

/* For small screens (mobiles) */
@media (max-width: 768px) {
  .countdown-timer {
    flex-direction: row;
    padding: 10px;
  }

  .time-section {
    width: 100%;
    /* Full width for stacked layout */
    margin: 10px 0;
  }

  .time-value {
    font-size: 2rem;
    /* Smaller font size for mobile */
  }

  .time-label {
    font-size: 0.9rem;
    /* Smaller label size */
  }
  .time-section:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0px;
    height: 80%;
    width: 1px;
    /* background-color: #00ff00;  */
    background-color: var(--primary-color);
  }
}

/* For very small screens (phones) */
@media (max-width: 480px) {
  .countdown-timer {
    margin-top: 25px;
  }


  .time-section {
    margin: 5px 0;

  }

  .time-section:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0px;
    height: 80%;
    width: 1px;
    /* background-color: #00ff00;  */
    background-color: var(--primary-color);
  }

  .time-value {
    font-size: 1.5rem;
    /* Smaller font for small screens */
    padding: 12px;
  }

  .time-label {
    font-size: 0.8rem;
  }
}