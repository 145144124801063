@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.header {
    width: 100%;
    color: white;
    padding: 0px 0px;
    text-align: center;
    font-family: "Space Grotesk", sans-serif;
    font-optical-sizing: auto;
    position: relative;
    background: #1C1C1C;
    background: linear-gradient(0deg, #1C1C1C, #193319);
    min-height: 100vh;
}

.header-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 0;

}

.logo {
    min-width: 200px;
    height: auto;
}

.header-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 0 50px;


}

.left-content {
    text-align: center;
    margin-bottom: 20px;

}

.right-content {
    position: relative;
}

.mainLogo {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.subtext {
    font-family: "Space Grotesk", sans-serif;
    font-optical-sizing: auto;
    font-size: 20px;
    margin-bottom: 15px;
    color: #E0E0E0;
    font-weight: bolder;
    text-shadow: 1px 1px 2px black;
}

.register-btn {
    background-color: #000000;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 54px;
    padding: 10px 30px;
    font-size: 16px;
    cursor: pointer;
    font-family: "Space Grotesk", sans-serif;
    transition: all 0.3s ease;
    margin-top: 10px;
    box-shadow: 0px 8px 4px 0px #30C54C40;

}

.register-btn:hover {
    background-color: var(--primary-color);
    color: black;
}

/* For small devices (max-width: 600px) */
@media (max-width: 480px) {
    .header {
        min-height: 100vh;
    }

    .header-top {


        padding: 20px 0px;


    }

    .header-main {
        padding: 0 20px;
    }

    .logo {
        max-width: 150px;

        width: 200px;
        min-width: 0;
    }

    .mainLogo {
        max-width: 250px;
    }

    .subtext {
        font-size: 20px;
        margin-top: 20px;
    }

    .register-btn {
        font-size: 18px;
        padding: 8px 20px;
    }
}

/* @media (max-width:720px) {
    .header {
        min-height: 90vh;
    }

    .header-top {


        padding: 20px 0px;


    }

    .header-main {
        padding: 0 20px;
    }

    .logo {
        max-width: 150px;

        width: 200px;
        min-width: 0;
    }

    .mainLogo {
        max-width: 250px;
    }

    .subtext {
        font-size: 20px;
        margin-top: 20px;
    }

    .register-btn {
        font-size: 18px;
        padding: 8px 20px;
    }

} */

@media (max-width: 720px) {
    .header-top {
        justify-content: space-evenly;
        padding: 10px 0px;
    }

    .header-main {
        padding: 0px 0px;

    }

    .logo {
        max-width: 160px;
    }

    .mainLogo {
        max-width: 250px;
    }

    .subtext {
        font-size: 18px;
    }

    .register-btn {
        font-size: 14px;
        padding: 8px 20px;
    }


}

@media (min-width: 720px) {
    .header-top {
        justify-content: space-evenly;
        padding: 10px 0px;
    }

    .logo {
        max-width: 180px;
    }

    .header-main {
        padding: 0 10px;

    }

    .mainLogo {
        max-width: 400px;
    }


}

@media (min-width: 992px) {
    .header-top {
        justify-content: space-between;
        padding: 10px 0;
    }

    .logo {
        max-width: 180px;
    }

    .header-main {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
    }

    .mainLogo {
        max-width: 400px;
    }

}

@media (min-width: 1200px) {
    .header-top {
        justify-content: space-between;
        padding: 10px 100px;
    }

    .logo {
        max-width: 250px;
    }

    .header-main {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 80px;
    }


    .mainLogo {
        max-width: 400px;
    }
}

@media (min-width: 1440px) {
    .header-top {
        padding: 10px 150px;
    }

    .header-main {
        padding: 20px 150px;
        margin-bottom: 30vh;

    }

    .mainLogo {
        min-width: 600px;
    }

    .left-content {
        margin-left: -200px;

    }

    .subtext {
        font-size: 30px;
        font-weight: bolder;
        display: flex;
        align-self: start;
    }

    .register-btn {
        font-size: 20px;
        padding: 14px 50px;
        margin-top: 20px;
        margin-left: -200px;
    }
}