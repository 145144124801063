@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.description-container {
    display: flex;
    flex-direction: row;
    gap: 5vw;
    align-items: center;
    color: white;
    font-family: "Space Grotesk", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
}

.greensquares {
    display: grid;
    gap: 0px;
    max-width: 800px;
    max-height: 100vh;
}

.greensquares .square {
    /* background-color: #00ff00; */
    background-color: var(--primary-color);
    width: 300px;
    height: 230px;
    aspect-ratio: 1;
    /* animation: pulse 2s infinite ease-in-out; */
    border-radius: 1px;
}

.greensquares .square:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
}

.greensquares .square:nth-child(2) {
    grid-column: 2;
    grid-row: 2;
}

.greensquares .square:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
    gap: 20px;

}

.greensquares .square:nth-child(4) {
    grid-column: 2;
    grid-row: 4;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.text-container {
    text-align: justify;
    /* This will distribute the text more evenly across the width */
    width: 50vw;
    /* You can increase the width to use more of the screen */
    max-width: 800px;
    /* Increase max width to expand the text area */
    text-align: justify;
    width: 90vw;
    max-width: 800px;

    flex-grow: 1;

}

.paragraph {
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1.5;
    color: var(--Theme-Primary, #50DA4C);
    text-align: justify;
}
sup{
    text-transform: lowercase;
}


.date {
    color: var(--Theme-Text, #FEFDF8);
    font-family: "Space Grotesk";
    font-weight: 500;
    font-size: 4.5vw;
    text-transform: uppercase;
}

.paragraph a {
    color: #ff00ff;
    text-decoration: none;
}

.paragraph a:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
    .description-container {
        flex-direction: column;
        align-items: center;
        gap: 2vh;
        height: 50%;

    }

    .greensquares {
        display: none;
        gap: 12px;
        width: 40vw;
        min-width: 150px;
        max-width: 800px;

    }

    .text-container {
        width: 90vw;
        text-align: center;
    }

    .date {
        font-size: 8vw;
    }

    .paragraph {
        font-size: 1.2rem;
    }

    .greensquares {
        width: 40vw;
    }

    .greensquares .square {
        height: 20vw;
    }
}

@media (max-width: 768px) {
    .description-container {
        flex-direction: column;
        align-items: center;
        gap: 2vh;
        min-height: 50%;

    }

    .greensquares {
        display: none;
        gap: 12px;
        width: 40vw;
        min-width: 150px;
        max-width: 800px;

    }

    .text-container {
        width: 90vw;
        text-align: center;
    }

    .date {
        font-size: 8vw;
    }

    .paragraph {
        font-size: 1.2rem;
    }

    .greensquares {
        width: 40vw;
    }

    .greensquares .square {
        height: 20vw;
    }
}

@media (max-width: 768px) {
    .description-container {
        margin-top: 0px;
        height: 50%;
    }

}



@media (min-width: 768px) {
    .description-container {
        flex-direction: column;
        align-items: center;
        gap: 2vh;


        margin-bottom: 50px;
        min-height: 60vh;

    }

    .greensquares {
        display: none;
        gap: 12px;

        max-width: 800px;

    }

    .text-container {
        width: 90vw;
        text-align: center;
    }

    .date {
        font-size: 6vw;
    }

    .paragraph {
        font-size: 1.5rem;
    }

}

@media (min-width: 1200px) {
    .description-container {
        display: flex;
        flex-direction: row;
        gap: 5vw;
        align-items: center;
        color: white;
        font-family: "Space Grotesk", sans-serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 20px;

        box-sizing: border-box;
        min-height: 100vh;



    }

    /* .greensquares {
        display: grid;
        gap: 0px;
        max-width: 800px;
        max-height: 100vh;
    }

    .greensquares .square {

        background-color: var(--primary-color);
        width: 240px;
        height: 145px;
        border-radius: 1px;
    } */

    .greensquares {
        display: grid;
        gap: 0px;
        max-width: 800px;
        max-height: 100vh;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        /* Responsive columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows occupying full height */
    }

    .greensquares .square {
        background-color: var(--primary-color);
        width: 100%;
        height: 100%;
        /* Make each square fill its grid cell */
        border-radius: 1px;
    }




    .text-container {


        width: 50vw;

        max-width: 800px;


        width: 90vw;
        max-width: 800px;

        flex-grow: 1;

    }

    .paragraph {
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 1.5;
        color: var(--Theme-Primary, #50DA4C);
        text-align: justify;
    }


    .date {
        color: var(--Theme-Text, #FEFDF8);
        font-family: "Space Grotesk";
        font-weight: 500;
        font-size: 4.5vw;
        text-transform: uppercase;
    }

    .paragraph a {
        color: #ff00ff;
        text-decoration: none;
    }

    .paragraph a:hover {
        text-decoration: underline;
    }
}

@media (min-width: 1440px) {
    .description-container {
        display: flex;
        flex-direction: row;
        gap: 20vw;
        align-items: center;
        color: white;
        font-family: "Space Grotesk", sans-serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        width: 100%;
        min-height: 100vh;


    }

    /* .greensquares {
        display: grid;
        gap: 0px;
        max-width: 800px;
        max-height: 100vh;
    }

    .greensquares .square {

        background-color: var(--primary-color);
        max-width: 300px;
        max-height: 230px;
        border-radius: 1px;
    } */
    .greensquares {
        display: grid;
        gap: 0px;
        max-width: 800px;
        max-height: 100vh;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        /* Responsive columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows occupying full height */

    }

    .greensquares .square {
        background-color: var(--primary-color);
        width: 100%;
        height: 100%;
        /* Make each square fill its grid cell */
        border-radius: 1px;
    }


    .text-container {
        justify-content: center;



    }
}